.button--active {
    background-color: #DBDCDE !important;
}

.share-popover {
    max-width: 300px;
    background-color: #fff;
    padding: 0;

    &.tooltip {
        pointer-events: all;
    }

    .close-popover {
        position: absolute;
        top: -3px;
        right: -3px;
        z-index: 1001;
        cursor: pointer;
        pointer-events: all;
    }
}

.tooltip {
    &.popover {
        .popover-inner {
            padding: 15px 24px !important;
        }
    }

    .tooltip-inner {
        padding: 0 !important;
        background: none !important;
    }

    .tooltip-arrow {
        border-color: #23202E !important;
    }
}

.header__search--cont {
    display: block;
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    position: relative;

    .header--search__results {
        position: absolute;
        top: 48px;
        width: 100%;
        box-shadow: 0 2px 4px #00000029;

        ul {
            display: block;
            width: 100%;
            max-width: 960px;
            margin: 0 auto;
            padding: 5px 0;
            background: #FFF;
            font-size: 16px;
            line-height: 20px;
            color: #000;
            list-style-type: none;

            li {
                padding: 10px 10px 10px 40px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    width: 18px;
                    height: 18px;
                    margin-top: -9px;
                    border-radius: 50%;
                }

                &.result--search::before {
                    background: #FFF url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="%2362727B" stroke-width="2.4" fill="none" stroke-linecap="round" stroke-linejoin="round"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>') 0 50% no-repeat;
                }

                &.result--video::before {
                    background: #20D3B3 url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="%23FFF" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg>') 55% 50%/8px 8px no-repeat;
                }

                &.result--iframe::before {
                    background: #9A9AF7 url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="%23FFF" d="M234.8 511.7L196 500.4c-4.2-1.2-6.7-5.7-5.5-9.9L331.3 5.8c1.2-4.2 5.7-6.7 9.9-5.5L380 11.6c4.2 1.2 6.7 5.7 5.5 9.9L244.7 506.2c-1.2 4.3-5.6 6.7-9.9 5.5zm-83.2-121.1l27.2-29c3.1-3.3 2.8-8.5-.5-11.5L72.2 256l106.1-94.1c3.4-3 3.6-8.2.5-11.5l-27.2-29c-3-3.2-8.1-3.4-11.3-.4L2.5 250.2c-3.4 3.2-3.4 8.5 0 11.7L140.3 391c3.2 3 8.2 2.8 11.3-.4zm284.1.4l137.7-129.1c3.4-3.2 3.4-8.5 0-11.7L435.7 121c-3.2-3-8.3-2.9-11.3.4l-27.2 29c-3.1 3.3-2.8 8.5.5 11.5L503.8 256l-106.1 94.1c-3.4 3-3.6 8.2-.5 11.5l27.2 29c3.1 3.2 8.1 3.4 11.3.4z"></path></svg>') 50% 50%/12px 12px no-repeat;
                }

                &.result--article::before {
                    background: #DC7DCD url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%23FFF" d="M14,0H3A1,1,0,0,0,2,1V23a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V8H15a1,1,0,0,1-1-1ZM5.5,17h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H5.5a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,5.5,17Zm0-5h13a.5.5,0,0,1,.5.5v1a.5.5,0,0,1-.5.5H5.5a.5.5,0,0,1-.5-.5v-1A.5.5,0,0,1,5.5,12Zm5-3h-5A.5.5,0,0,1,5,8.5v-1A.5.5,0,0,1,5.5,7h5a.5.5,0,0,1,.5.5v1A.5.5,0,0,1,10.5,9Z"></path><polygon fill="%23FFF" points="21.414 6 16 6 16 0.586 21.414 6"></polygon></svg>') 50% 50%/10px 10px no-repeat;
                }

                &:hover {
                    cursor: pointer;
                    background: #f4f4f4;
                }

                a {
                    p.result--title {
                        color: #555;
                        font-weight: 500;
                        font-size: 16px;
                    }

                    p.result--description {
                        font-size: 14px;
                        color: #999999;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}


.tiptap-menubar {
    background: #EEEFF0 !important;
}

.tiptap-menubar button {
    background: #EEEFF0 !important;
}

.btn-content {
    color: #62727B !important;
}

.editor__content {
    color: #37474F !important;
}

.tutorial {
    width: 420px !important;
    max-width: 420px !important;
}

.v-step {
    max-width: 420px !important;
    padding: 0 !important;
    color: inherit !important;
}

.shepherd-header {
    background-color: white !important;
    padding-bottom: 0px !important;
}

.next-button {
    background-color: #ee2e24!important;
    color: white !important;
}

.end-tour-button {
    position: fixed;
    left: 0;
    color: #1097E2 !important;
    background: inherit !important;
}

.step-description {
    color: #62727B !important;
}

.shepherd-target {
    padding: 16px !important;
}

.shepherd-title {
    flex: auto !important;
    flex-direction: column !important;
}

.shepherd-text {
    line-height: 25px !important;
}

.shepherd-content {
    position: relative;
    max-width: 420px;
    padding: 20px;
    border-radius: 4px;
    background-color: #FFF;
    box-shadow: none !important;
}

.shepherd-arrow::before {
    background-color: white !important;
}

@media only screen and (min-width: 1024px) {
    .feed-step .shepherd-arrow:before {
        bottom: 115px !important;
    }
}
